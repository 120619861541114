@font-face {
  font-family: "Playfair Display";   /*Can be any text*/
  src: local("Playfair Display"),
    url("https://fonts.googleapis.com/css?family=Playfair Display") format("woff2"),
    url("https://fonts.googleapis.com/css?family=Playfair Display") format("truetype");
    font-weight: bolder;
    font-style: normal;
}

* {
  font-family: 'Playfair Display' !important;
  font-style: normal;
  text-align: justify;
  color: #664229;
  scroll-behavior: auto !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.home_banner_div {
  position: relative;
  height: 100vh;
  background-image: url("./assets/intro_bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  animation: shrink 10s infinite alternate ;
}

@keyframes shrink {
  0% {
    background-size: 100% 100%;
  }
  100% {
    background-size: 120% 120%;
  }
}

.home_banner_text {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ue_btn_active.animated-button-active.thar-three {
  border-radius: 0%;
	color: #fff;
	cursor: pointer;
	display: block;
	position: relative;
  box-shadow: none;
	border: 2px solid transparent;
	border-bottom: 2px solid #d7b38c;
	transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1)  0s;
}
.ue_btn.animated-button.thar-three {
  border-radius: 0%;
	color: #fff;
	cursor: pointer;
	display: block;
	position: relative;
  box-shadow: none;
	border: 2px solid #d7b38c;
	/* border-bottom: 2px solid #d7b38c; */
	transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1)  0s;
}

.ue_btn.animated-button.thar-three:hover {
	color: #000 !important;
	background-color: transparent;
	/* text-shadow: nthree; */
}
.ue_btn.animated-button.thar-three:hover:before {
  box-shadow: none;
	left: 0%;
	right: auto;
	width: 100%;
}
.ue_btn.animated-button.thar-three:before {
  border-radius: 0%;
	display: block;
	position: absolute;
  box-shadow: none;
	top: 0px;
	right: 0px;
	height: 100%;
	width: 0px;
	z-index: -1;
	content: '';
	color: #000 !important;
	background: #d7b38c;
	transition: all 0.5s cubic-bezier(0.50, 0, 0.50, 1) 0s;
}


.logo_img {
  margin: 2;
  width: 5%;
  object-fit: cover;
  object-position: center;
}

@media only screen and (max-width: 768px) {
  .logo_img {
    width: 15%;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(1turn);
  }
}


.bgTEST {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0px;
  z-index: 0;
  background-color: #ffe4c7;
}

.layer {
  position: fixed;
  top: 0px;
  margin: auto;
  width: 100%;
  z-index: 100;
  min-height: 50vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: top center;
  background-repeat: no-repeat;
}

.parallax {
  height: 100vh;
  overflow-x: hidden;
  overflow-y: initial;
  perspective: 1px;
}

.parallax-group {
  height: 100vh;
  transform-style: preserve-3d;
}

.parallax-group .dumm:nth-child(1) {
  background-color: #ffe4c7;
  margin-top: 600px;
  background-color: #d9d1b8;
  transform: translateZ(-12px) scale(13);
}
.parallax-group .dumm:nth-child(1):before {
  content: "";
  width: 100%;
  height: 200px;
  position: absolute;
  bottom: 100%;
  left: 0;
  /* background-image: linear-gradient(135deg, transparent 66%, #d9d1b8 66.01%), linear-gradient(45deg, #d9d1b8 34%, transparent 34.01%); */
  background-position: 25px 0px;
  background-size: 100% 100%;
  background-repeat: repeat-x;
}

.content {
  position: relative;
  background-color: transparent;
  font-family: "Arial";
  letter-spacing: 10px;
  text-transform: uppercase;
  line-height: 40px;
  z-index: 10;
  width: 100%;
  font-size: 15px;
  text-align: center;
  color: white;
  margin-top: 1000px;
  transform: translateZ(-2px) scale(3);
}

.floating { 
  animation-name: floating;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes floating {
  0% { transform: translate(0, 0px) rotate(2deg); }
  25% { transform: translate(0, 20px) rotate(0deg); }
  50% { transform: translate(0, 0px) rotate(-2deg); }
  75% { transform: translate(0, 20px) rotate(0deg); }
  100% { transform: translate(0, 0px) rotate(2deg); }
}

/* PROJECT proj-card CSS */

:root {
  --proj-card-transition-time: 01s;
}

.proj-cards-wrapper {
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 4rem;
  padding: 4rem;
  margin: 0;
  width: max-content;
}

.proj-card {
  --bg-filter-opacity: 1;
  background-image: linear-gradient(rgba(0,0,0,var(--bg-filter-opacity)),rgba(0,0,0,var(--bg-filter-opacity))), var(--bg-img);
  height: 20em;
  width: 15em;
  font-size: 1.5em;
  padding: 1em;
  display: flex;
  align-items: flex-end;
  background-size: cover;
  background-position: center;
  transition: all, var(--proj-card-transition-time);
  position: relative;
  overflow: hidden;
  border: 10px solid #d7b38c;
  text-decoration: none;
}

.proj-card:hover {
  transform: rotate(0);
  color: #664229;
}

.proj-card .project_head {
  color: white;
  margin: 0;
  font-size: 1.5em;
  line-height: 1.2em;
}

.proj-card p {
  font-size: 0.75em;
  margin-top: 0.5em;
  line-height: 2em;
}

.proj-card .proj-tags {
  display: flex;
}

.proj-card .proj-tags .proj-tag{
  font-size: 0.75em;
  background: rgba(255,255,255,0.5);
  border-radius: 0.3rem;
  padding: 0 0.5em;
  margin-right: 0.5em;
  line-height: 1.5em;
  transition: all, var(--proj-card-transition-time);
}

.proj-card:hover .proj-tags .proj-tag {
  background: var(--color);
  color: #664229;
}

.proj-card .date {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0.75em;
  padding: 1em;
  line-height: 1em;
  opacity: .8;
}

.proj-card:before, .proj-card:after, .project_head::after {
  content: '';
  transform: scale(0);
  transform-origin: center;
  border-radius: 25%;
  position: absolute;
  left: -50%;
  top: -50%;
  z-index: -5;
  transition: all, var(--proj-card-transition-time);
  transition-timing-function: ease-in-out;
}

/* .proj-card:before {
  background: #ddd;
  width: 250%;
  height: 250%;
} */

.proj-card:after, .project_head::after {
  background: url(./assets/proj_1/proj_1_2.jpg);
  filter: brightness(50%);
  color: #664229;
  width: 200%;
  height: 200%;
}

.proj-card:hover:before, .proj-card:hover:after {
  transform: scale(1);
}

.proj-card-grid-space .num {
  font-size: 3em;
  margin-bottom: 1.2rem;
  margin-left: 1rem;
}

/* 02 */

.proj-card2 {
  --bg-filter-opacity: 1;
  background-image: linear-gradient(rgba(0,0,0,var(--bg-filter-opacity)),rgba(0,0,0,var(--bg-filter-opacity))), var(--bg-img);
  height: 20em;
  width: 15em;
  font-size: 1.5em;
  padding: 1em;
  display: flex;
  align-items: flex-end;
  background-size: cover;
  background-position: center;
  transition: all, var(--proj-card-transition-time);
  position: relative;
  overflow: hidden;
  border: 10px solid #d7b38c;
  text-decoration: none;
}

.proj-card2:hover {
  transform: rotate(0);
  color: #664229;
}

.proj-card2 .project_head2 {
  color: white;
  margin: 0;
  font-size: 1.5em;
  line-height: 1.2em;
}

.proj-card2 p {
  font-size: 0.75em;
  margin-top: 0.5em;
  line-height: 2em;
}

.proj-card2 .proj-tags2 {
  display: flex;
}

.proj-card2 .proj-tags2 .proj-tag2{
  font-size: 0.75em;
  background: rgba(255,255,255,0.5);
  border-radius: 0.3rem;
  padding: 0 0.5em;
  margin-right: 0.5em;
  line-height: 1.5em;
  transition: all, var(--proj-card-transition-time);
}

.proj-card2:hover .proj-tags2 .proj-tag2 {
  background: var(--color);
  color: #664229;
}

.proj-card2 .date {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0.75em;
  padding: 1em;
  line-height: 1em;
  opacity: .8;
}

.proj-card2:before, .proj-card2:after, .project_head2::after {
  content: '';
  transform: scale(0);
  transform-origin: center;
  border-radius: 25%;
  position: absolute;
  left: -50%;
  top: -50%;
  z-index: -5;
  transition: all, var(--proj-card-transition-time);
  transition-timing-function: ease-in-out;
}

/* .proj-card2:before {
  background: #ddd;
  width: 250%;
  height: 250%;
} */

.proj-card2:after, .project_head2::after {
  background: url(./assets/proj_2/proj_2_2.jpg);
  filter: brightness(50%);
  color: #664229;
  width: 200%;
  height: 200%;
}

.proj-card2:hover:before, .proj-card2:hover:after {
  transform: scale(1);
}

.proj-card-grid-space .num {
  font-size: 3em;
  margin-bottom: 1.2rem;
  margin-left: 1rem;
}

/* 03 */

.proj-card3 {
  --bg-filter-opacity: 1;
  background-image: linear-gradient(rgba(0,0,0,var(--bg-filter-opacity)),rgba(0,0,0,var(--bg-filter-opacity))), var(--bg-img);
  height: 20em;
  width: 15em;
  font-size: 1.5em;
  padding: 1em;
  display: flex;
  align-items: flex-end;
  background-size: cover;
  background-position: center;
  transition: all, var(--proj-card-transition-time);
  position: relative;
  overflow: hidden;
  border: 10px solid #d7b38c;
  text-decoration: none;
}

.proj-card3:hover {
  transform: rotate(0);
  color: #664229;
}

.proj-card3 .project_head3 {
  color: white;
  margin: 0;
  font-size: 1.5em;
  line-height: 1.2em;
}

.proj-card3 p {
  font-size: 0.75em;
  margin-top: 0.5em;
  line-height: 2em;
}

.proj-card3 .proj-tags3 {
  display: flex;
}

.proj-card3 .proj-tags3 .proj-tag3{
  font-size: 0.75em;
  background: rgba(255,255,255,0.5);
  border-radius: 0.3rem;
  padding: 0 0.5em;
  margin-right: 0.5em;
  line-height: 1.5em;
  transition: all, var(--proj-card-transition-time);
}

.proj-card3:hover .proj-tags3 .proj-tag3 {
  background: var(--color);
  color: #664229;
}

.proj-card3 .date3 {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0.75em;
  padding: 1em;
  line-height: 1em;
  opacity: .8;
}

.proj-card3:before, .proj-card3:after, .project_head3::after {
  content: '';
  transform: scale(0);
  transform-origin: center;
  border-radius: 25%;
  position: absolute;
  left: -50%;
  top: -50%;
  z-index: -5;
  transition: all, var(--proj-card-transition-time);
  transition-timing-function: ease-in-out;
}

/* .proj-card3:before {
  background: #ddd;
  width: 250%;
  height: 250%;
} */

.proj-card3:after, .project_head3::after {
  background: url(./assets/proj_3/proj_3_2.jpg);
  filter: brightness(50%);
  color: #664229;
  width: 200%;
  height: 200%;
}

.proj-card3:hover:before, .proj-card3:hover:after {
  transform: scale(1);
}

/* 04 */

.proj-card4 {
  --bg-filter-opacity: 1;
  background-image: linear-gradient(rgba(0,0,0,var(--bg-filter-opacity)),rgba(0,0,0,var(--bg-filter-opacity))), var(--bg-img);
  height: 20em;
  width: 15em;
  font-size: 1.5em;
  padding: 1em;
  display: flex;
  align-items: flex-end;
  background-size: cover;
  background-position: center;
  transition: all, var(--proj-card-transition-time);
  position: relative;
  overflow: hidden;
  border: 10px solid #d7b38c;
  text-decoration: none;
}

.proj-card4:hover {
  transform: rotate(0);
  color: #664229;
}

.proj-card4 .project_head3 {
  color: white;
  margin: 0;
  font-size: 1.5em;
  line-height: 1.2em;
}

.proj-card4 p {
  font-size: 0.75em;
  margin-top: 0.5em;
  line-height: 2em;
}

.proj-card4 .proj-tags3 {
  display: flex;
}

.proj-card4 .proj-tags3 .proj-tag3{
  font-size: 0.75em;
  background: rgba(255,255,255,0.5);
  border-radius: 0.3rem;
  padding: 0 0.5em;
  margin-right: 0.5em;
  line-height: 1.5em;
  transition: all, var(--proj-card-transition-time);
}

.proj-card4:hover .proj-tags3 .proj-tag3 {
  background: var(--color);
  color: #664229;
}

.proj-card4 .date3 {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0.75em;
  padding: 1em;
  line-height: 1em;
  opacity: .8;
}

.proj-card4:before, .proj-card4:after, .project_head3::after {
  content: '';
  transform: scale(0);
  transform-origin: center;
  border-radius: 25%;
  position: absolute;
  left: -50%;
  top: -50%;
  z-index: -5;
  transition: all, var(--proj-card-transition-time);
  transition-timing-function: ease-in-out;
}

/* .proj-card4:before {
  background: #ddd;
  width: 250%;
  height: 250%;
} */

.proj-card4:after, .project_head3::after {
  background: url(./assets/proj_4/proj_4_2.jpg);
  filter: brightness(50%);
  color: #664229;
  width: 200%;
  height: 200%;
}

.proj-card4:hover:before, .proj-card4:hover:after {
  transform: scale(1);
}


/* 05 */


.proj-card5 {
  --bg-filter-opacity: 1;
  background-image: linear-gradient(rgba(0,0,0,var(--bg-filter-opacity)),rgba(0,0,0,var(--bg-filter-opacity))), var(--bg-img);
  height: 20em;
  width: 15em;
  font-size: 1.5em;
  padding: 1em;
  display: flex;
  align-items: flex-end;
  background-size: cover;
  background-position: center;
  transition: all, var(--proj-card-transition-time);
  position: relative;
  overflow: hidden;
  border: 10px solid #d7b38c;
  text-decoration: none;
}

.proj-card5:hover {
  transform: rotate(0);
  color: #664229;
}

.proj-card5 .project_head3 {
  color: white;
  margin: 0;
  font-size: 1.5em;
  line-height: 1.2em;
}

.proj-card5 p {
  font-size: 0.75em;
  margin-top: 0.5em;
  line-height: 2em;
}

.proj-card5 .proj-tags3 {
  display: flex;
}

.proj-card5 .proj-tags3 .proj-tag3{
  font-size: 0.75em;
  background: rgba(255,255,255,0.5);
  border-radius: 0.3rem;
  padding: 0 0.5em;
  margin-right: 0.5em;
  line-height: 1.5em;
  transition: all, var(--proj-card-transition-time);
}

.proj-card5:hover .proj-tags3 .proj-tag3 {
  background: var(--color);
  color: #664229;
}

.proj-card5 .date3 {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0.75em;
  padding: 1em;
  line-height: 1em;
  opacity: .8;
}

.proj-card5:before, .proj-card5:after, .project_head3::after {
  content: '';
  transform: scale(0);
  transform-origin: center;
  border-radius: 25%;
  position: absolute;
  left: -50%;
  top: -50%;
  z-index: -5;
  transition: all, var(--proj-card-transition-time);
  transition-timing-function: ease-in-out;
}

/* .proj-card5:before {
  background: #ddd;
  width: 250%;
  height: 250%;
} */

.proj-card5:after, .project_head3::after {
  background: url(./assets/proj_5/proj_5_3.png);
  filter: brightness(50%);
  color: #664229;
  width: 200%;
  height: 200%;
}

.proj-card5:hover:before, .proj-card5:hover:after {
  transform: scale(1);
}

/* 06 */


.proj-card6 {
  --bg-filter-opacity: 1;
  background-image: linear-gradient(rgba(0,0,0,var(--bg-filter-opacity)),rgba(0,0,0,var(--bg-filter-opacity))), var(--bg-img);
  height: 20em;
  width: 15em;
  font-size: 1.5em;
  padding: 1em;
  display: flex;
  align-items: flex-end;
  background-size: cover;
  background-position: center;
  transition: all, var(--proj-card-transition-time);
  position: relative;
  overflow: hidden;
  border: 10px solid #d7b38c;
  text-decoration: none;
}

.proj-card6:hover {
  transform: rotate(0);
  color: #664229;
}

.proj-card6 .project_head3 {
  color: white;
  margin: 0;
  font-size: 1.5em;
  line-height: 1.2em;
}

.proj-card6 p {
  font-size: 0.75em;
  margin-top: 0.5em;
  line-height: 2em;
}

.proj-card6 .proj-tags3 {
  display: flex;
}

.proj-card6 .proj-tags3 .proj-tag3{
  font-size: 0.75em;
  background: rgba(255,255,255,0.5);
  border-radius: 0.3rem;
  padding: 0 0.5em;
  margin-right: 0.5em;
  line-height: 1.5em;
  transition: all, var(--proj-card-transition-time);
}

.proj-card6:hover .proj-tags3 .proj-tag3 {
  background: var(--color);
  color: #664229;
}

.proj-card6 .date3 {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0.75em;
  padding: 1em;
  line-height: 1em;
  opacity: .8;
}

.proj-card6:before, .proj-card6:after, .project_head3::after {
  content: '';
  transform: scale(0);
  transform-origin: center;
  border-radius: 25%;
  position: absolute;
  left: -50%;
  top: -50%;
  z-index: -5;
  transition: all, var(--proj-card-transition-time);
  transition-timing-function: ease-in-out;
}

/* .proj-card6:before {
  background: #ddd;
  width: 250%;
  height: 250%;
} */

.proj-card6:after, .project_head3::after {
  background: url(./assets/proj_6/proj_6_2.png);
  filter: brightness(50%);
  color: #664229;
  width: 200%;
  height: 200%;
}

.proj-card6:hover:before, .proj-card6:hover:after {
  transform: scale(1);
}

/* 07 */

.proj-card7 {
  --bg-filter-opacity: 1;
  background-image: linear-gradient(rgba(0,0,0,var(--bg-filter-opacity)),rgba(0,0,0,var(--bg-filter-opacity))), var(--bg-img);
  height: 20em;
  width: 15em;
  font-size: 1.5em;
  padding: 1em;
  display: flex;
  align-items: flex-end;
  background-size: cover;
  background-position: center;
  transition: all, var(--proj-card-transition-time);
  position: relative;
  overflow: hidden;
  border: 10px solid #d7b38c;
  text-decoration: none;
}

.proj-card7:hover {
  transform: rotate(0);
  color: #664229;
}

.proj-card7 .project_head3 {
  color: white;
  margin: 0;
  font-size: 1.5em;
  line-height: 1.2em;
}

.proj-card7 p {
  font-size: 0.75em;
  margin-top: 0.5em;
  line-height: 2em;
}

.proj-card7 .proj-tags3 {
  display: flex;
}

.proj-card7 .proj-tags3 .proj-tag3{
  font-size: 0.75em;
  background: rgba(255,255,255,0.5);
  border-radius: 0.3rem;
  padding: 0 0.5em;
  margin-right: 0.5em;
  line-height: 1.5em;
  transition: all, var(--proj-card-transition-time);
}

.proj-card7:hover .proj-tags3 .proj-tag3 {
  background: var(--color);
  color: #664229;
}

.proj-card7 .date3 {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0.75em;
  padding: 1em;
  line-height: 1em;
  opacity: .8;
}

.proj-card7:before, .proj-card7:after, .project_head3::after {
  content: '';
  transform: scale(0);
  transform-origin: center;
  border-radius: 25%;
  position: absolute;
  left: -50%;
  top: -50%;
  z-index: -5;
  transition: all, var(--proj-card-transition-time);
  transition-timing-function: ease-in-out;
}

/* .proj-card7:before {
  background: #ddd;
  width: 250%;
  height: 250%;
} */

.proj-card7:after, .project_head3::after {
  background: url(./assets/proj_7/proj_7_2.png);
  filter: brightness(50%);
  color: #664229;
  width: 200%;
  height: 200%;
}

.proj-card7:hover:before, .proj-card7:hover:after {
  transform: scale(1);
}

/* 08 */

.proj-card8 {
  --bg-filter-opacity: 1;
  background-image: linear-gradient(rgba(0,0,0,var(--bg-filter-opacity)),rgba(0,0,0,var(--bg-filter-opacity))), var(--bg-img);
  height: 20em;
  width: 15em;
  font-size: 1.5em;
  padding: 1em;
  display: flex;
  align-items: flex-end;
  background-size: cover;
  background-position: center;
  transition: all, var(--proj-card-transition-time);
  position: relative;
  overflow: hidden;
  border: 10px solid #d7b38c;
  text-decoration: none;
}

.proj-card8:hover {
  transform: rotate(0);
  color: #664229;
}

.proj-card8 .project_head3 {
  color: white;
  margin: 0;
  font-size: 1.5em;
  line-height: 1.2em;
}

.proj-card8 p {
  font-size: 0.75em;
  margin-top: 0.5em;
  line-height: 2em;
}

.proj-card8 .proj-tags3 {
  display: flex;
}

.proj-card8 .proj-tags3 .proj-tag3{
  font-size: 0.75em;
  background: rgba(255,255,255,0.5);
  border-radius: 0.3rem;
  padding: 0 0.5em;
  margin-right: 0.5em;
  line-height: 1.5em;
  transition: all, var(--proj-card-transition-time);
}

.proj-card8:hover .proj-tags3 .proj-tag3 {
  background: var(--color);
  color: #664229;
}

.proj-card8 .date3 {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0.75em;
  padding: 1em;
  line-height: 1em;
  opacity: .8;
}

.proj-card8:before, .proj-card8:after, .project_head3::after {
  content: '';
  transform: scale(0);
  transform-origin: center;
  border-radius: 25%;
  position: absolute;
  left: -50%;
  top: -50%;
  z-index: -5;
  transition: all, var(--proj-card-transition-time);
  transition-timing-function: ease-in-out;
}

/* .proj-card8:before {
  background: #ddd;
  width: 250%;
  height: 250%;
} */

.proj-card8:after, .project_head3::after {
  background: url(./assets/proj_8/proj_8_2.png);
  filter: brightness(50%);
  color: #664229;
  width: 200%;
  height: 200%;
}

.proj-card8:hover:before, .proj-card8:hover:after {
  transform: scale(1);
}

/* 09 */

.proj-card9 {
  --bg-filter-opacity: 1;
  background-image: linear-gradient(rgba(0,0,0,var(--bg-filter-opacity)),rgba(0,0,0,var(--bg-filter-opacity))), var(--bg-img);
  height: 20em;
  width: 15em;
  font-size: 1.5em;
  padding: 1em;
  display: flex;
  align-items: flex-end;
  background-size: cover;
  background-position: center;
  transition: all, var(--proj-card-transition-time);
  position: relative;
  overflow: hidden;
  border: 10px solid #d7b38c;
  text-decoration: none;
}

.proj-card9:hover {
  transform: rotate(0);
  color: #664229;
}

.proj-card9 .project_head3 {
  color: white;
  margin: 0;
  font-size: 1.5em;
  line-height: 1.2em;
}

.proj-card9 p {
  font-size: 0.75em;
  margin-top: 0.5em;
  line-height: 2em;
}

.proj-card9 .proj-tags3 {
  display: flex;
}

.proj-card9 .proj-tags3 .proj-tag3{
  font-size: 0.75em;
  background: rgba(255,255,255,0.5);
  border-radius: 0.3rem;
  padding: 0 0.5em;
  margin-right: 0.5em;
  line-height: 1.5em;
  transition: all, var(--proj-card-transition-time);
}

.proj-card9:hover .proj-tags3 .proj-tag3 {
  background: var(--color);
  color: #664229;
}

.proj-card9 .date3 {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0.75em;
  padding: 1em;
  line-height: 1em;
  opacity: .8;
}

.proj-card9:before, .proj-card9:after, .project_head3::after {
  content: '';
  transform: scale(0);
  transform-origin: center;
  border-radius: 25%;
  position: absolute;
  left: -50%;
  top: -50%;
  z-index: -5;
  transition: all, var(--proj-card-transition-time);
  transition-timing-function: ease-in-out;
}

/* .proj-card9:before {
  background: #ddd;
  width: 250%;
  height: 250%;
} */

.proj-card9:after, .project_head3::after {
  background: url(./assets/proj_9/proj_9_2.png);
  filter: brightness(50%);
  color: #664229;
  width: 200%;
  height: 200%;
}

.proj-card9:hover:before, .proj-card9:hover:after {
  transform: scale(1);
}

/* 10 */

.proj-card10 {
  --bg-filter-opacity: 1;
  background-image: linear-gradient(rgba(0,0,0,var(--bg-filter-opacity)),rgba(0,0,0,var(--bg-filter-opacity))), var(--bg-img);
  height: 20em;
  width: 15em;
  font-size: 1.5em;
  padding: 1em;
  display: flex;
  align-items: flex-end;
  background-size: cover;
  background-position: center;
  transition: all, var(--proj-card-transition-time);
  position: relative;
  overflow: hidden;
  border: 10px solid #d7b38c;
  text-decoration: none;
}

.proj-card10:hover {
  transform: rotate(0);
  color: #664229;
}

.proj-card10 .project_head3 {
  color: white;
  margin: 0;
  font-size: 1.5em;
  line-height: 1.2em;
}

.proj-card10 p {
  font-size: 0.75em;
  margin-top: 0.5em;
  line-height: 2em;
}

.proj-card10 .proj-tags3 {
  display: flex;
}

.proj-card10 .proj-tags3 .proj-tag3{
  font-size: 0.75em;
  background: rgba(255,255,255,0.5);
  border-radius: 0.3rem;
  padding: 0 0.5em;
  margin-right: 0.5em;
  line-height: 1.5em;
  transition: all, var(--proj-card-transition-time);
}

.proj-card10:hover .proj-tags3 .proj-tag3 {
  background: var(--color);
  color: #664229;
}

.proj-card10 .date3 {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0.75em;
  padding: 1em;
  line-height: 1em;
  opacity: .8;
}

.proj-card10:before, .proj-card10:after, .project_head3::after {
  content: '';
  transform: scale(0);
  transform-origin: center;
  border-radius: 25%;
  position: absolute;
  left: -50%;
  top: -50%;
  z-index: -5;
  transition: all, var(--proj-card-transition-time);
  transition-timing-function: ease-in-out;
}

/* .proj-card10:before {
  background: #ddd;
  width: 250%;
  height: 250%;
} */

.proj-card10:after, .project_head3::after {
  background: url(./assets/proj_10/proj_10_2.png);
  filter: brightness(50%);
  color: #664229;
  width: 200%;
  height: 200%;
}

.proj-card10:hover:before, .proj-card10:hover:after {
  transform: scale(1);
}

/* 11 */

.proj-card11 {
  --bg-filter-opacity: 1;
  background-image: linear-gradient(rgba(0,0,0,var(--bg-filter-opacity)),rgba(0,0,0,var(--bg-filter-opacity))), var(--bg-img);
  height: 20em;
  width: 15em;
  font-size: 1.5em;
  padding: 1em;
  display: flex;
  align-items: flex-end;
  background-size: cover;
  background-position: center;
  transition: all, var(--proj-card-transition-time);
  position: relative;
  overflow: hidden;
  border: 10px solid #d7b38c;
  text-decoration: none;
}

.proj-card11:hover {
  transform: rotate(0);
  color: #664229;
}

.proj-card11 .project_head3 {
  color: white;
  margin: 0;
  font-size: 1.5em;
  line-height: 1.2em;
}

.proj-card11 p {
  font-size: 0.75em;
  margin-top: 0.5em;
  line-height: 2em;
}

.proj-card11 .proj-tags3 {
  display: flex;
}

.proj-card11 .proj-tags3 .proj-tag3{
  font-size: 0.75em;
  background: rgba(255,255,255,0.5);
  border-radius: 0.3rem;
  padding: 0 0.5em;
  margin-right: 0.5em;
  line-height: 1.5em;
  transition: all, var(--proj-card-transition-time);
}

.proj-card11:hover .proj-tags3 .proj-tag3 {
  background: var(--color);
  color: #664229;
}

.proj-card11 .date3 {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0.75em;
  padding: 1em;
  line-height: 1em;
  opacity: .8;
}

.proj-card11:before, .proj-card11:after, .project_head3::after {
  content: '';
  transform: scale(0);
  transform-origin: center;
  border-radius: 25%;
  position: absolute;
  left: -50%;
  top: -50%;
  z-index: -5;
  transition: all, var(--proj-card-transition-time);
  transition-timing-function: ease-in-out;
}

/* .proj-card11:before {
  background: #ddd;
  width: 250%;
  height: 250%;
} */

.proj-card11:after, .project_head3::after {
  background: url(./assets/proj_11/proj_11_2.png);
  filter: brightness(50%);
  color: #664229;
  width: 200%;
  height: 200%;
}

.proj-card11:hover:before, .proj-card11:hover:after {
  transform: scale(1);
}

.proj-card-grid-space .num {
  font-size: 3em;
  margin-bottom: 1.2rem;
  margin-left: 1rem;
}

.info {
  font-size: 1.2em;
  display: flex;
  padding: 1em 3em;
  height: 3em;
}

.info .proj-img {
  height: 3em;
  margin-right: 0.5em;
}

.info h1 {
  font-size: 1em;
  font-weight: normal;
}

/* MEDIA QUERIES */
@media screen and (max-width: 1285px) {
  .proj-cards-wrapper {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 900px) {
  .proj-cards-wrapper {
    grid-template-columns: 1fr;
  }
  .info {
    justify-content: center;
  }
  .proj-card-grid-space .num {
    margin-left: 0;
    text-align: center;
  }
}

@media screen and (max-width: 500px) {
  .proj-cards-wrapper {
    padding: 4rem 2rem;
  }
  .proj-card {
    max-width: calc(100vw - 4rem);
  }
}

@media screen and (max-width: 450px) {
  .info {
    display: block;
    text-align: center;
  }
  .info h1 {
    margin: 0;
  }
}

/* TEAM CARDS */
.content-wrapper {
  margin: 0 auto;
  max-width: 1200px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  padding: 0.5rem;
}

.teams-card {
  border: 0px solid aqua;
  margin: 0.5rem;
  position: relative;
  height: 40vh;
  overflow: hidden;
  flex: 1;
  min-width: 30%;
  box-shadow: 0 0 1.5rem #664229;
  backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
}

@media (min-width: 900px) {

.teams-card {
    height: 40vh
}
  }

.teams-card::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0) linear-gradient(to bottom, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.7) 80%);;
      z-index: 0;
    }

.teams-card__card-link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
/*     background: rgba(255,0,0,.5); */
  }

.teams-card__image {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    transition: transform 3s ease;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    position: relative;
    z-index: -1;
  }

.teams-card__text-wrapper {
    position: absolute;
    bottom: 0rem;
    padding: 1rem;
    color: white;
/*     background-color: rgba(0, 0, 0, 0.4); */
    transition: background-color 1.5s ease;
  }

.teams-card__title {
    transition: color 1s ease;
    margin-bottom: .5rem;
    color: white;
  }

.teams-card__post-date {
    margin-bottom: .5rem;
    color: #664229;
  }

.teams-card__details-wrapper {
    max-height: 20vh;
    opacity: 0;
    transition: max-height 1.5s ease, opacity 1s ease;
  }

@media (min-width: 900px) {
    .teams-card:hover .teams-card__details-wrapper {
      max-height: 30vh;
      opacity: 1;
    }
    .teams-card:hover .teams-card__text-wrapper {
      background-color: rgba(255, 255, 255, 254.6);
    }
    .teams-card:hover .teams-card__title {
      color: #664229;
    }
    .teams-card:hover .teams-card__image {
      transform: scale(1.2);
      z-index: -1;
    }
  }

.teams-card__excerpt {
    font-weight: 300;
  }

.teams-card__read-more {
    background: #664229;
    color: white;
    display: block;
    padding: 0.4rem 0.6rem;
    border-radius: 0.3rem;
    margin-top: 1rem;
    border: 1px solid #444;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    text-decoration: none;
    width: 7rem;
    margin-left: auto;
    position: relative;
    z-index: 5;
  }

.teams-card__read-more i {
      position: relative;
      left: 0.2rem;
      color: #888;
      transition: left 0.5s ease, color 0.6s ease;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
    }

.teams-card__read-more:hover i {
      left: 0.5rem;
      color: #664229;
    }

.about_slide_div1 {
  position: relative;
  height: 70vh;
  background-image: url("./assets/proj_3/proj_3_3.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.about_slide_div2 {
  position: relative;
  height: 70vh;
  background-image: url("./assets/proj_2/proj_2_4.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.about_slide_div3 {
  position: relative;
  height: 70vh;
  background-image: url("./assets/proj_1/proj_1_6.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.about_slide_div4 {
  position: relative;
  height: 70vh;
  background-image: url("./assets/proj_1/proj_1_7.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.bg_body {
	background: linear-gradient(0deg, white, #d7b38c);
	background-size: 100% 100%;
	height: 50vh;
}

.bg_body_gallery {
	background: linear-gradient(0deg, white, #d7b38c);
	background-size: 100% 100%;
	height: 30vh;
}

.gallery-container {
  scroll-behavior: smooth !important;
}